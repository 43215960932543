import React, { useState, useRef, useEffect } from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSquare, faTrashAlt, faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import AOSService from "../../services/AOSServices";
import { GetIDCardOriginName } from '../../services/Utils';
import Compress from "compress.js";
import * as Sentry from "@sentry/react";

import "./IDCardUploader.css";
import back from '../../assets/IDCard_back.png';
import front from '../../assets/IDCard_front.png';
import sam1 from '../../assets/sample_1.png';
import sam2 from '../../assets/sample_2.png';
import sam3 from '../../assets/sample_3.png';
import sam4 from '../../assets/sample_4.png';

import { setLocale } from "yup";

const IDCardUploader = React.memo(({ UserRef, onIDCardReady }) => {
    library.add(faCircle, faSquare, faTrashAlt, faSpinner, faCheckCircle, faTimesCircle);

    const DocStatus = useRef({ Front: false, Back: false });

    const FileChangeHandler = ({ ImgName, Status }) => {
        if (ImgName.toLowerCase() === "front") {
            DocStatus.current.Front = Status;
        } else if (ImgName.toLowerCase() === "back") {
            DocStatus.current.Back = Status;
        }
        onIDCardReady(DocStatus.current.Front && DocStatus.current.Back);
    };

    const IDCardUploadButton = ({ UserRef, Side, BGImage, Title, onFileChanged }) => {
        const compress = new Compress();
        const initialRender = useRef(true);
        const [uploadProcess, setUploadProcess] = useState(0);
        const [file, setFile] = useState("");
        const [loading, setLoading] = useState(false);
        const inputFileRef = useRef();

        useEffect(() => {
            if (initialRender.current) {
                //console.log(`${Side} file or IDCardImage changed`, "useEffect");

                if (!file && UserRef.IDCardOrigin && UserRef.RefCode) {
                    AOSService.GetIDCard(UserRef.IDCardOrigin, UserRef.RefCode, Side)
                        .then(d => {
                            const base64 = btoa(
                                new Uint8Array(d).reduce(
                                    (data, byte) => data + String.fromCharCode(byte),
                                    '',
                                ),
                            );
                            if (base64) {
                                onFileChanged({ ImgName: Side, Status: true });
                                setFile("data:;base64," + base64);
                            }
                        });
                }
                initialRender.current = false;
            }

            return () => {
                //console.log("unmount IDCardUploadButton", "useEffect")
                if (file) URL.revokeObjectURL(file);
            };
        });

        const showFileDialog = (event) => {
            inputFileRef.current.click();
        };

        const deleteImage = () => {
            onFileChanged({ ImgName: Side, Status: false });
            setFile(null);
        };

        const handleChange = (event) => {
            const fileObject = event.target.files[0];
            if (!fileObject) return;
            renderImage([fileObject]);
        };

        const renderImage = (fileObject) => {
            compress.compress(fileObject, {
                size: 2, // the max size in MB, defaults to 2MB
                quality: .90, // the quality of the image, max is 1,
                maxWidth: 1920, // the max width of the output image, defaults to 1920px
                maxHeight: 1920, // the max height of the output image, defaults to 1920px
                resize: true, // defaults to true, set false if you do not want to resize the image width and height
                rotate: false, // See the rotation section below
            }).then((data) => {
                // returns an array of compressed images
                //console.log(data[0], "compress aynce");
                const img = data[0];
                const base64str = img.data;
                const imgExt = img.ext;
                const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
                resizedFile.fileName = img.alt;
                setFile(URL.createObjectURL(resizedFile));

                setLoading(true);
                AOSService.UploadID(UserRef.IDCardOrigin, UserRef.RefCode, Side, resizedFile,
                    (event) => {
                        setUploadProcess(Math.round((100 * event.loaded) / event.total));
                    }).then(req => {
                        if (req && !req.Result) {
                            switch (req.Info) {
                                case 1:
                                    alert(`上傳失敗檔案格式不支援，請重新上載`);
                                    break;
                                case 2:
                                    alert(`身份證號已經註冊開戶`);
                                    break;
                                default:
                                    alert(`上傳失敗或證件文字不清晰，請重新上載`);
                            }
                            deleteImage();
                        } else {
                            onFileChanged({ ImgName: Side, Status: true });
                        }

                        setLoading(false);
                    }).catch(error => {
                        Sentry.captureException({
                            errorName: 'AOSService.UploadID',
                            message: error,
                        });
                    });
            }).catch(error => {
                Sentry.captureException({
                    errorName: 'compress',
                    message: error,
                });
            });

        };

        const LoadingPanel = () => {
            return (
                <div className="ffg-uploader-loading">
                    <div className="ffg-uploader-loading-icon-container">
                        <FontAwesomeIcon icon={faSpinner} color="rgb(237, 43, 95)" size="5x" pulse />
                    </div>
                </div>
            );
        }

        return (
            <>
                {file ? (
                    <div className="ffg-uploader-btn m-2">
                        {loading && (<LoadingPanel />)}
                        <div className="ffg-uploader-preview">
                            <img src={file} alt="" />
                            <span className="fa-layers fa-fw fa-2x" onClick={deleteImage}>
                                <FontAwesomeIcon icon="circle" color="rgb(40, 63, 123)" />
                                <FontAwesomeIcon icon={faTrashAlt} inverse transform="shrink-6" />
                            </span>
                            {uploadProcess > 0 && uploadProcess < 100 && (
                                <span className="ffg-uploader-process">
                                    <span className="position-absolute badge rounded-pill bg-danger">
                                        {uploadProcess}%
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="ffg-uploader-btn" onClick={showFileDialog}>
                        <div className="ffg-uploader-card">
                            <img src={BGImage} alt="" />
                            <p>{Title}</p>
                        </div>
                        <input type="file"
                            ref={inputFileRef}
                            accept="image/*" className="id-uploader-input"
                            onChange={handleChange} />
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="row">
            <div className="col-12">
                <h3 className="pt-3"> 上傳證件 ({GetIDCardOriginName(UserRef.IDCardOrigin)})</h3>
            </div>
            <div className="col-12 col-sm-6 col-lg-6">
                <IDCardUploadButton key="FileUploadFront"
                    BGImage={front}
                    Title={"身份证正面"}
                    Side={"Front"}
                    UserRef={UserRef}
                    onFileChanged={FileChangeHandler}
                />
            </div>

            <div className="col-12 col-sm-6 col-lg-6">
                <IDCardUploadButton key="FileUploadBack"
                    BGImage={back}
                    Title={"身份证反面"}
                    Side={"Back"}
                    UserRef={UserRef}
                    onFileChanged={FileChangeHandler}
                />
            </div>

            <div className="col-12 mt-3"></div>

            <div className="col-sm-2 offset-sm-2 col-6">
                <img src={sam1} alt="" className="img-fluid ffg-uploader-sample" />
                <p>
                    <FontAwesomeIcon icon={faCheckCircle} color="rgb(25, 135, 84)" />
                    正确示例
                </p>    
            </div>
            <div className="col-sm-2 col-6">
                <img src={sam2} alt="" className="img-fluid ffg-uploader-sample" />
                <p>
                    <FontAwesomeIcon icon={faTimesCircle} color="rgb(237, 43, 95)" />
                    边角缺失
                </p>
            </div>
            <div className="col-sm-2 col-6">
                <img src={sam3} alt="" className="img-fluid ffg-uploader-sample" />
                <p>
                    <FontAwesomeIcon icon={faTimesCircle} color="rgb(237, 43, 95)" />
                    信息模糊
                </p>
            </div>
            <div className="col-sm-2 col-6">
                <img src={sam4} alt="" className="img-fluid ffg-uploader-sample" />
                <p>
                    <FontAwesomeIcon icon={faTimesCircle} color="rgb(237, 43, 95)" />
                    闪光强烈
                </p>
            </div>


            <div className="col-lg-8 offset-lg-1">
                {/* {docIDCardFront && (<span>{docIDCardFront.length}</span>)} */}
                <p className="text-start my-3 mx-lg-5 mx-1">
                    1. 拍摄时请将证件平放，手机横向拍摄; <br />
                    2. 确保身份证 <span className="text-danger">边框完整、文字清晰可见;</span><br />
                    3. 若点击上传无反应，请先确认是否开启相机权限。
                </p>

            </div>
        </div>
    );
});

export default IDCardUploader;